import React, { FC, useEffect, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-premium";
import { Button, IconButton, Typography } from "@mui/material";
import { Close, CloudUpload, Delete, Download } from "@mui/icons-material";
import Loader from "src/components/Loader/Loader";
import { AllocatorService } from "src/services/AllocatorService";
import { downloadFile } from "src/utils";
import { ACCEPTED_SETTINGS_TYPES } from "src/products/Allocator/constant";
import { HttpError } from "src/utils/HttpError";
import { StyledFieldMappingGrid, StyledGridButton } from "./FieldMappingGrid.styled";
import { getFirstPathSegment } from "src/services/Utility";
import { THEME } from "src/constants/Theme";

const FieldMappingGrid: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const columns: GridColDef[] = [
        {
            field: "fieldsConfig",
            headerName: "Fields config",
            sortable: false,
            flex: 1,
            minWidth: 210,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            flex: 1,
            minWidth: 130,
            renderCell: (params: GridRenderCellParams) => 
                params?.row?.custom ?
                (
                    <StyledGridButton
                        startIcon={<Download />}
                        onClick={handleDownload}
                    >
                        Download
                    </StyledGridButton>
                ) : (
                    <div {...getRootProps()}>
                        <StyledGridButton 
                            startIcon={<CloudUpload />}
                        >
                            <input data-testid="uploadInput" {...getInputProps()} />
                            Upload
                        </StyledGridButton>
                    </div>
                )
        },
        {
            field: "remove",
            headerName: "",
            sortable: false,
            flex: 1,
            minWidth: 110,
            renderCell: (params: GridRenderCellParams) => 
                params?.row?.custom && (
                <StyledGridButton
                    startIcon={<Delete />}
                    onClick={handleDelete}
                >
                    Remove
                </StyledGridButton>
            )
        },
    ];

    const { getRootProps, getInputProps } = useDropzone({
        noDrag: true,
        multiple: false,
        accept: ACCEPTED_SETTINGS_TYPES,
        onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            if (rejectedFiles?.length) {
                handleRejectedFileCode(rejectedFiles[0]?.errors[0]?.code);
            } else {
                handleUpload(acceptedFiles);
            }
        },
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchAccountSettings = async () => {
            setIsLoading(true);

            try {
                const accountSettings = await allocatorService.getAccountSettings();

                setRows([
                    {
                        id: 1,
                        custom: accountSettings?.fieldMapping?.custom,
                        fieldsConfig: accountSettings?.fieldMapping?.filename,
                    },
                ]);
            } catch (error) {
                enqueueSnackbar("Error fetching account settings", { variant: "error" });
            } finally {
                setIsLoading(false);
            }
        };
        fetchAccountSettings();
    }, []);

    const handleRejectedFileCode = (errorCode: ErrorCode | string) => {
        if (errorCode === ErrorCode.FileInvalidType) {
            enqueueSnackbar("Unable to parse uploaded file due to invalid format", { variant: "error" });
        } else {
            enqueueSnackbar("Can't upload file", { variant: "error" });
        }
    };

    const handleDownload = async () => {
        try {
            const settingsDownloadable = await allocatorService.downloadAccountSettings();
            const { blob, contentDispositionHeader } = settingsDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Downloaded successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download Config file", { variant: "error" });
        }
    };

    const handleDelete = async () => {
        setIsLoading(true);

        try {
            const accountSettings = await allocatorService.deleteAccountSettings();

            setRows([
                {
                    id: 1,
                    custom: accountSettings?.fieldMapping?.custom,
                    fieldsConfig: accountSettings?.fieldMapping?.filename,
                },
            ]);
        } catch (error) {
            enqueueSnackbar("Error deleting Config file", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleNavigate = (id: number | undefined) => {
        window.open(
            `${
                window.location.origin
            }/${getFirstPathSegment()}/errors/${id}`,
            "_blank"
        );
    };

    const handleUpload = async (files: File[]) => {
        setIsLoading(true);

        const formData = new FormData();
        files?.forEach((file: File) =>
            formData.append(`file`, file, file.name)
        );

        try {
            const accountSettings = await allocatorService.uploadSettings(formData);
            setRows([
                {
                    id: 1,
                    custom: accountSettings?.fieldMapping?.custom,
                    fieldsConfig: accountSettings?.fieldMapping?.filename,
                },
            ]);
            enqueueSnackbar("Custom Config file is uploaded successfully and can be applied in parsing", { variant: "success" });
        } catch (error) {
            if(error instanceof HttpError) {
                const errorResponse = JSON.parse(error?.body);

                if (errorResponse?.errorId) {
                    const action = (snackbarId: SnackbarKey) => (
                        <>
                            <Button color="inherit" size="small" onClick={() => handleNavigate(errorResponse?.errorId)}>
                                View Error Log
                            </Button>
                            <IconButton
                                color="inherit"
                                sx={{ p: 0.5, marginLeft: 1 }}
                                onClick={() => { closeSnackbar(snackbarId) }}
                            >
                                <Close />
                            </IconButton>
                        </>
                    );

                    enqueueSnackbar(errorResponse?.errorDetails, { variant: "error", action });
                } else {
                    enqueueSnackbar(errorResponse?.errorDetails, { variant: "error" });
                }
            } else {
                enqueueSnackbar("Error uploading Config file", { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <Loader color={THEME.palette.primary}/>;

    return (
        <>
            <Typography sx={{ marginBottom: 2 }}>
                Upload XLS or XLSX config file to map the fields.
            </Typography>
            <StyledFieldMappingGrid
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnReorder
                disableColumnResize
                density="compact"
            />
        </>
    );
};

export default FieldMappingGrid;
