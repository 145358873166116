import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ErrorLog.scss";
import { IconButton, Typography } from "@mui/material";
import { AllocatorService } from "src/services";
import { ALLOCATOR_LOG_TYPES, AllocatorErrorLog } from "src/types";
import Loader from "src/components/Loader/Loader";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import { Download } from "@mui/icons-material";
import { downloadFile } from "src/utils";
import { THEME } from "src/constants/Theme";

interface ErrorLogProps {
    type?: ALLOCATOR_LOG_TYPES;
}

const ErrorLog: FC<ErrorLogProps> = ({ type }) => {
    const allocatorService = AllocatorService.getInstance();

    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorLog, setErrorLog] = useState<AllocatorErrorLog | null>(null);
    const { id } = useParams();

    useEffect(() => {
        fetchErrorLog();
    }, []);

    const fetchErrorLog = async () => {
        setIsLoading(true);

        try {
            const errorLogResponse = await allocatorService.getErrorLog(
                Number(id)
            );
            setErrorLog(errorLogResponse);
        } catch (error) {
            setError(error as Error | null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            const logsDownloadable = await allocatorService.downloadLogs(Number(id));
            const { blob, contentDispositionHeader } = logsDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
        } catch (error) {
            console.error("Failed to download Logs", error);
        }
    };


    if (error)
        return (
            <ErrorMessage
                error={`Error fetching Error Log`}
            />
    );

    if (!error && isLoading) return <Loader color={THEME.palette.primary} />;

    return (
        <div className="error-log-container">
            <div className="error-log-landing">
                <div className="error-log-header">
                    <h6>{errorLog?.filename} {type} log</h6>
                    {!!errorLog?.errorDetails && (
                        <IconButton title="Download" onClick={handleDownload}>
                            <Download />
                        </IconButton>
                    )}
                </div>
                {
                    !errorLog?.errorDetails ? (
                        <Typography sx={{marginTop: 1 }}>
                            Unexpected server error.
                        </Typography>
                    ) : (
                        <>
                            <ul>
                            {
                                errorLog?.errorDetails?.errors?.map((error, index) => {
                                    return (
                                        <li key={index}>
                                            {error?.spreadsheetName && `Spreadsheet ${error?.spreadsheetName}`} Line #{error?.rowNumber} {error?.errorMessage}
                                        </li>
                                    )
                                })
                            }
                            </ul>
                            {type === ALLOCATOR_LOG_TYPES.WARNING && (
                                <>
                                    <Typography sx={{marginTop: 1 }}>
                                        {errorLog?.errorDetails?.rowsProcessed} records read successfully.
                                    </Typography>
                                    <Typography>
                                        {errorLog?.errorDetails?.rowsFailed} records could not be read.
                                    </Typography>
                                    <Typography>
                                        {errorLog?.errorDetails?.totalRows} records processed.
                                    </Typography>
                                </>
                            )}
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default ErrorLog;
